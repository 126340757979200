/*!
 *
 * _base.scss
 *
 */
/* wildcard
   ========================================================================== */
*,
*::before,
*::after {
    box-sizing: border-box;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

/* viewport
   ========================================================================== */
@-ms-viewport {
    width: device-width;
}

/* touch-action
   ========================================================================== */
a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
-ms-touch-action: manipulation;
touch-action: manipulation; }
