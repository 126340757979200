html, body, #wrap {
    height: 100%;
}

#wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

#contents {
    svg {
        width: auto;
        min-width: 10rem;
        height: auto;
        min-height: 10rem;
        animation: Flash1 8s infinite;
    }
    @keyframes Flash1{
        50%{
          opacity: 0.1;
        }
      }

    h1 {
        display: none;
    }
}
